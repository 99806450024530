import './style.css';
import GeoJSON from 'ol/format/GeoJSON.js';
import Map from 'ol/Map.js';
import View from 'ol/View.js';
import {OSM, Vector as VectorSource} from 'ol/source.js';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer.js';
import {fromLonLat,toLonLat} from 'ol/proj.js';
import Overlay from 'ol/Overlay.js';
/*import XYZ from 'ol/source/XYZ.js';
import {toStringHDMS} from 'ol/coordinate.js';
import { ATTRIBUTION } from 'ol/source/OSM';*/




const source1 = new VectorSource({
  url: 'Point_lic.json',
  format: new GeoJSON(),
});

const source2 = new VectorSource({
  url: 'Poly_lic.json',
  format: new GeoJSON(),
});



const addis= fromLonLat([38.7525, 9.0192]);
const golol=fromLonLat([35.172932,8.615555]);
const jibota=fromLonLat([38.840955,5.816347]);
const legadembi=fromLonLat([38.899841,5.712862]);
const sakaro=fromLonLat([38.873307,5.694231]);
const metekel=fromLonLat([36.054907,10.341089]);
const werri=fromLonLat([39.060725,13.759292]);
const asosa123=fromLonLat([34.718411,10.130351]);
const kamashi=fromLonLat([35.907746,9.560886]);
const qersa=fromLonLat([36.87875,7.718407]);
const miesa=fromLonLat([39.770604,5.465421]);
const kuni=fromLonLat([40.947541,9.04041]);
const merahabete=fromLonLat([38.74441,10.040176]);
const hakimgara=fromLonLat([42.118846,9.306258]);
const dejen1=fromLonLat([38.177201,10.148304]);
const dejen2=fromLonLat([38.184942,10.156005]);
const dejen3=fromLonLat([38.130542,10.185028]);
const daleti=fromLonLat([35.239522,10.022923]);
const enkonte=fromLonLat([35.956986,10.583417]);
const mankush=fromLonLat([35.371462,11.265311]);
const babile=fromLonLat([42.338736,9.203961]);
const chimit=fromLonLat([37.560204,10.05995]);
const adamitulu=fromLonLat([38.753149,7.844179]);
const ledi=fromLonLat([40.675486,11.168785]);


const container = document.getElementById('popup');
const content = document.getElementById('popup-content');
const closer = document.getElementById('popup-closer');

const overlay = new Overlay({
  element: container,
  autoPan: {
    animation: {
      duration: 250,
    },
  },
});

closer.onclick = function () {
  overlay.setPosition(undefined);
  closer.blur();
  return false;
};

const pointLic = new VectorLayer({
  source: source1,
  style: {
    /*'fill-color': 'rgba(200, 200, 255, 0.3)',
    'stroke-width': 1,
    'stroke-color': '#319FD3',*/
    'circle-radius': 6,
    'circle-fill-color': 'red',//rgba(255, 255, 255, 0.6)',
    'circle-stroke-width': 1,
    'circle-stroke-color': '#319FD3',
    'z-index':1,
  },
});

const polyLic = new VectorLayer({
  source: source2,
  style: {
    'fill-color': 'rgba(200, 200, 255, 0.3)',
    'stroke-width': 1,
    'stroke-color': '#319FD3',
    /*'circle-radius': 6,
    'circle-fill-color': 'red',//rgba(255, 255, 255, 0.6)',
    'circle-stroke-width': 1,
    'circle-stroke-color': '#319FD3',*/
  },
});


const view = new View({
  center: addis,
  zoom: 6,
});
const map = new Map({
  layers: [
    new TileLayer({
      source: new OSM(),
    }),
    pointLic,
    polyLic,
  ],
  target: 'map',
    view: view,
  overlays:[overlay],
});



function getTimeRemaining(endtime){ let input = endtime.split("/");
  let input2 = [input[2],input[1],input[0]];
  let input3 = input2.join("-");
  const total = Date.parse(input3) - Date.parse(new Date());
  const days = Math.floor( total/(1000*60*60*24) );
if (days>=0){return days}
else if (days<0){return "License Expired!"} else {return "License non available"};
  
}

async function fetchJSON(url) {
  const response = await fetch(url);
  return await response.json();
}


fetchJSON('Poly_lic.json')
            .then(function(exp) { 
              document.getElementById("btn13").style.color = "red";
        // do what you want to do with `data` here...
        exp.features.forEach(function(feature) {
                var exp_date = getTimeRemaining(feature.properties['Expiration']);
                if (exp_date=="License Expired!"){document.getElementById(feature.properties['id']).style.color = "red";}
                else if (exp_date=="License non available"){document.getElementById(feature.properties['id']).style.color = "blue";}
                else {document.getElementById(feature.properties['id']).style.color = "green";}
                //if (typeof(exp_date)=="string"){document.getElementById(feature.properties['id']).style.color = "red";}
            });

})



/*map.on('singleclick', function (evt) {
  const coordinate = evt.coordinate;
  const hdms = toStringHDMS(toLonLat(coordinate));
  content.innerHTML = '<p>You clicked here:</p><code>' + hdms + '</code>';
  overlay.setPosition(coordinate);
});
*/

map.on("click",function(evt){

  var feature1 = map.forEachFeatureAtPixel(evt.pixel,function(feature1,layer){
    if(layer=pointLic){
      return feature1;
    }
   
  });

  var feature2 = map.forEachFeatureAtPixel(evt.pixel,function(feature2,layer){
    if(layer=pointLic){
      return feature2;
    }
   
  });






  if(feature1){
var popContent = '<p>'+feature1.get('Commodity')+'</p>';
popContent += '<p>License Type: '+feature1.get('License')+'</p>';
popContent += '<p>Issue Date: '+feature1.get('Issue_Date')+'</p>';
popContent += '<p>Expiration Date: '+feature1.get('Expiration')+'</p>';
popContent += '<p>X Coordinate: '+feature1.get('X_center')+'</p>';
popContent += '<p>Y Coordinate: '+feature1.get('Y_center')+'</p>';
popContent += '<p>Remaining Days: '+getTimeRemaining(feature1.get('Expiration'))+'</p>';
popContent += '<p><a href='+feature1.get('View')+' target="_blank" rel="noopener noreferrer">View License</a></p>';




content.innerHTML=popContent;
const coordinate = evt.coordinate;
overlay.setPosition(coordinate);

}

if(feature2){
  var popContent = '<p>'+feature2.get('Commodity')+'</p>';
  popContent += '<p>License Type: '+feature2.get('License')+'</p>';
  popContent += '<p>Owner: '+feature2.get('Owner')+'</p>';
  popContent += '<p>Region: '+feature2.get('Region')+'</p>';
  popContent += '<p>Zone: '+feature2.get('Zone')+'</p>';
  popContent += '<p>Wereda: '+feature2.get('Woreda')+'</p>';
  popContent += '<p>Issued by: '+feature2.get('Issued_by')+'</p>';
  popContent += '<p>Area: '+feature2.get('Area_KM2')+'sq.km</p>';
  popContent += '<p>Issue Date: '+feature2.get('Issue_Date')+'</p>';
  popContent += '<p>Expiration Date: '+feature2.get('Expiration')+'</p>';
  popContent += '<p>Remaining Days: '+getTimeRemaining(feature2.get('Expiration'))+'</p>';
  popContent += '<p><a href='+feature2.get('View')+' target="_blank" rel="noopener noreferrer">View License</a></p>';
    
  
  
  
  
  content.innerHTML=popContent;
  const coordinate = evt.coordinate;
  overlay.setPosition(coordinate);
  
  }
  



})

closer.onClick = function(){

overlay.setPosition(undefined);
}




function onClick(id, callback) {
  document.getElementById(id).addEventListener('click', callback);
}

onClick('btn1', function () {
  view.animate({
    center: golol,
    duration: 2000,
    zoom:10
  });
});

onClick('btn2', function () {
  view.animate({
    center: jibota,
    duration: 2000,
    zoom:13
  });
});

onClick('btn3', function () {
  view.animate({
    center: legadembi,
    duration: 2000,
    zoom:14
  });
});

onClick('btn4', function () {
  view.animate({
    center: sakaro,
    duration: 2000,
    zoom:14
  });
});

onClick('btn5', function () {
  view.animate({
    center: metekel,
    duration: 2000,
    zoom:13
  });
});

onClick('btn6', function () {
  view.animate({
    center: werri,
    duration: 2000,
    zoom:11
  });
});

onClick('btn7', function () {
  view.animate({
    center: asosa123,
    duration: 2000,
    zoom:14
  });
});

onClick('btn8', function () {
  view.animate({
    center: kamashi,
    duration: 2000,
    zoom:12
  });
});

onClick('btn9', function () {
  view.animate({
    center: qersa,
    duration: 2000,
    zoom:15
  });
});

onClick('btn10', function () {
  view.animate({
    center: miesa,
    duration: 2000,
    zoom:12
  });
});

onClick('btn11', function () {
  view.animate({
    center: kuni,
    duration: 2000,
    zoom:14
  });
});

onClick('btn12', function () {
  view.animate({
    center: merahabete,
    duration: 2000,
    zoom:13
  });
});

onClick('btn13', function () {
  view.animate({
    center: hakimgara,
    duration: 2000,
    zoom:14
  });
});

onClick('btn14', function () {
  view.animate({
    center: dejen1,
    duration: 2000,
    zoom:15
  });
});

onClick('btn15', function () {
  view.animate({
    center: dejen2,
    duration: 2000,
    zoom:15
  });
});

onClick('btn16', function () {
  view.animate({
    center: dejen3,
    duration: 2000,
    zoom:15
  });
});

onClick('btn17', function () {
  view.animate({
    center: daleti,
    duration: 2000,
    zoom:14
  });
});

onClick('btn18', function () {
  view.animate({
    center: enkonte,
    duration: 2000,
    zoom:16
  });
});

onClick('btn19', function () {
  view.animate({
    center: mankush,
    duration: 2000,
    zoom:16
  });
});

onClick('btn20', function () {
  view.animate({
    center: babile,
    duration: 2000,
    zoom:16
  });
});

onClick('btn21', function () {
  view.animate({
    center: chimit,
    duration: 2000,
    zoom:16
  });
});

onClick('btn22', function () {
  view.animate({
    center: adamitulu,
    duration: 2000,
    zoom:13
  });
});

onClick('btn23', function () {
  view.animate({
    center: ledi,
    duration: 2000,
    zoom:13
  });
});










// change mouse cursor when over marker
map.on('pointermove', function (e) {
  const pixel = map.getEventPixel(e.originalEvent);
  const hit = map.hasFeatureAtPixel(pixel);
 document.getElementById(map.getTarget()).style.cursor = hit ? 'pointer' : '';
});

